.circle-progress-bar {
  position: relative;
  width: 100px;
  height: 100px;
}

svg {
  position: absolute;
  width: 100%;
  height: 100%;
}

.circle1 {
  fill: #293042;
  /* stroke: #e6e6e6; */
  stroke: #293042;
}

.progress1 {
  stroke: #1BB408;
  fill: #293042;
  transition: stroke-dashoffset 1.5s ease-in-out;
  stroke-linecap: round;
}

.percentage2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  font-weight: bold;
  color: white;
}