.circle-progress-bar {
  position: relative;
  width: 100px;
  height: 100px;
}

svg {
  position: absolute;
  width: 100%;
  height: 100%;
}

circle {
  fill: white;
  stroke: #e6e6e6;
  /* stroke: white; */
}

.progress {
  stroke: #1BB408;
  transition: stroke-dashoffset 1.5s ease-in-out;
  stroke-linecap: round;
}

.percentage {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  font-weight: bold;
  color: black;
}

/* ------------------------------------------------------------------ */


.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -60px;
  content: "";
  top: 100%;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}


.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}


.container .slice {
  transition-duration: 0.3s;
  transition-property: filter, opacity;
  filter: saturate(100%);
  opacity: 1;
  cursor: pointer;

  .slice1 {
    visibility: hidden;
  }
}

.container.hasHighlight .slice {
  filter: saturate(50%);
  opacity: 1;
}

.container.hasHighlight .slice:hover {
  filter: saturate(100%);
  opacity: 1;

  .slice1 .tooltiptext {
    visibility: visible;
  }
}