body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.App {
  /* text-align: center; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: center; */
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

input[type="date"] {
    position: relative;
}

/* create a new arrow, because we are going to mess up the native one
see "List of symbols" below if you want another, you could also try to add a font-awesome icon.. */
input[type="date"]:after {
    font-family: "Font Awesome 5 Free";
    font-weight: bold;
    content: "\1F4C5";
    color: black;
    padding: 0 5px;
    font-size: 20px;

}

/* change color of symbol on hover */
/* input[type="date"]:hover:after {
    color: #bf1400;
} */

/* make the native arrow invisible and stretch it over the whole field so you can click anywhere in the input field to trigger the native datepicker*/
input[type="date"]::-webkit-calendar-picker-indicator {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    /* color: transparent; */
    /* background: transparent; */
}

/* adjust increase/decrease button */
/* input[type="date"]::-webkit-inner-spin-button {
    z-index: 1;
} */

/* adjust clear button */
/* input[type="date"]::-webkit-clear-button {
     z-index: 1;
 } */
.circle-progress-bar {
  position: relative;
  width: 100px;
  height: 100px;
}

svg {
  position: absolute;
  width: 100%;
  height: 100%;
}

.circle1 {
  fill: #293042;
  /* stroke: #e6e6e6; */
  stroke: #293042;
}

.progress1 {
  stroke: #1BB408;
  fill: #293042;
  transition: stroke-dashoffset 1.5s ease-in-out;
  stroke-linecap: round;
}

.percentage2 {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-size: 24px;
  font-weight: bold;
  color: white;
}
.circle-progress-bar {
  position: relative;
  width: 100px;
  height: 100px;
}

svg {
  position: absolute;
  width: 100%;
  height: 100%;
}

circle {
  fill: white;
  stroke: #e6e6e6;
  /* stroke: white; */
}

.progress {
  stroke: #1BB408;
  transition: stroke-dashoffset 1.5s ease-in-out;
  stroke-linecap: round;
}

.percentage {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-size: 24px;
  font-weight: bold;
  color: black;
}

/* ------------------------------------------------------------------ */


.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -60px;
  content: "";
  top: 100%;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}


.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}


.container .slice {
  transition-duration: 0.3s;
  transition-property: opacity, -webkit-filter;
  transition-property: filter, opacity;
  transition-property: filter, opacity, -webkit-filter;
  -webkit-filter: saturate(100%);
          filter: saturate(100%);
  opacity: 1;
  cursor: pointer;

  .slice1 {
    visibility: hidden;
  }
}

.container.hasHighlight .slice {
  -webkit-filter: saturate(50%);
          filter: saturate(50%);
  opacity: 1;
}

.container.hasHighlight .slice:hover {
  -webkit-filter: saturate(100%);
          filter: saturate(100%);
  opacity: 1;

  .slice1 .tooltiptext {
    visibility: visible;
  }
}
